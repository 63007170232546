<template>
  <div class="scheduler">
    <template v-if="loaded">

      <SchedulerFilter
          :dates="dates"
          @subtract="onSubtractDateRange"
          @add="onAddDateRange"
          @reload="reloadEvents"
          @today="onSetDateRangeToToday"
          :teams="teams"
          :workspaces="workspaces"
          :users="employees"
          :categories="categories"
          @filterTeams="teams => selectedTeams = teams"
          @filterWorkspaces="workspaces => selectedWorkspaces = workspaces"
          @filterUsers="users => selectedUsers = users"
          @filterCategories="categories => selectedCategories = categories"
      ></SchedulerFilter>

      <SchedulerHeadline :dates="dates" :holidays="holidays"></SchedulerHeadline>

      <div class="scheduler-container">
        <SchedulerBorders :dates="dates" :holidays="holidays"></SchedulerBorders>

        <div class="scheduler-overlay">
          <SchedulerRow
              v-for="employee in filteredEmployees"
              :key="employee.id"
              :employee="employee"
              :dates="dates"
              :dateRangeEvents="dateRangeEvents"
              @eventUpdate="onEventUpdate"
              @clickEvent="openEventPopup"
              @clickDate="onClickDate"
          >
          </SchedulerRow>
        </div>
      </div>

    </template>

    <template v-if="loaded">
      <event-popup :active.sync="eventPopupActive"
                   :event="currentEvent"
                   @saved="reloadEvents"
                   @close="onEventPopupClosed">

      </event-popup>
    </template>

  </div>
</template>

<script>
import SchedulerRow from "./components/SchedulerRow";
import SchedulerBorders from "./components/SchedulerBorders";
import SchedulerHeadline from "./components/SchedulerHeadline";

import ApiService from "../../../api";
import moment from 'moment';
import SchedulerFilter from "./components/SchedulerFilter";
import cloneDeep from "lodash/cloneDeep";
import EventPopup from "../Calendar/components/EventPopup";
import * as qs from "qs";
import {mapGetters} from "vuex";

moment.locale("de");

export default {
  name: "Scheduler",
  components: {EventPopup, SchedulerFilter, SchedulerHeadline, SchedulerBorders, SchedulerRow},

  data() {
    return {
      loaded: false,
      employees: [],
      events: [],
      categories: [],
      holidays: [],
      teams: [],
      workspaces: [],
      dayCount: 11,
      startDate: moment(),
      dates: [],
      eventPopupActive: false,
      currentEvent: null,
      selectedTeams: [],
      selectedWorkspaces: [],
      selectedUsers: [],
      selectedCategories: []
    }
  },

  computed: {
    ...mapGetters(['userId']),
    teamsPromise() {
      return ApiService.get('teams');
    },
    workspacesPromise() {
        return ApiService.get('workspaces');
    },
    employeesPromise() {
      return ApiService.get('users', {
          params: {
              filter: [{
                  field: 'workspace_id',
                  value: 'all'
              }],
          },
          paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });
    },
    categoriesPromise() {
      return ApiService.get('categories', {
        params: {
          filter: [{
            field: 'usable_on',
            value: 'calendar'
          }],
        },
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });
    },
    holidaysPromise() {
      return ApiService.get('calendar-holidays');
    },
    eventsPromise() {
      return ApiService.get('calendar-events', {
        params: {
          includes: ['users.teams'],
        },
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });
    },
    specialEventsPromise() {
      return ApiService.get('calendar-special-events');
    },
    sprintEventsPromise() {
      return ApiService.get('project/sprint/calendar-events');
    },
    dateRangeEvents() {
      if (!this.dates || !this.dates.length) {
        return [];
      }

      const start = moment(this.dates[0]).startOf("day").unix();
      const end = moment(this.dates[this.dates.length - 1]).endOf("day").unix();

      let filteredEvents = this.events;

      if (this.selectedCategories && this.selectedCategories.length > 0) {
        filteredEvents = filteredEvents.filter(event => {
          return event.categories.find(category => this.selectedCategories.includes(category.id)) !== undefined;
        });
      }

      return filteredEvents.filter(event => {
        if (moment(event.start_at).unix() >= start && moment(event.start_at).unix() <= end) {
          return true;
        }

        if (event.end_at && moment(event.end_at).unix() <= end && moment(event.end_at).unix() >= start) {
          return true;
        }

        return false;
      }).sort((a, b) => {
        const aStart = moment(a.start_at).startOf('day').unix();
        const bStart = moment(b.start_at).startOf('day').unix();
        const aEnd = a.end_at ? moment(a.end_at).endOf('day').unix() : moment(a.start_at).endOf('day').unix();
        const bEnd = b.end_at ? moment(b.end_at).endOf('day').unix() : moment(a.start_at).endOf('day').unix();

        if (aStart < bStart) return -1
        if (bStart < aStart) return 1
        if (aEnd < bEnd) return 1
        if (bEnd < aEnd) return -1

        return (aStart - aEnd) > (bStart - bEnd) ? -1 : 1
      });
    },

    filteredEmployees() {
      let employees = this.employees;

      if (this.selectedUsers && this.selectedUsers.length > 0) {
        employees = employees.filter(employee => {
          return this.selectedUsers.includes(employee.id);
        });
      }

      if (this.selectedTeams && this.selectedTeams.length > 0) {
        employees = employees.filter(employee => {
          return employee.teams.find(team => this.selectedTeams.includes(team.id)) !== undefined;
        });
      }

        if (this.selectedWorkspaces && this.selectedWorkspaces.length > 0) {
            employees = employees.filter(employee => {
                return this.selectedWorkspaces.includes(employee.workspace.id)
            });
        }

      return employees;
    }
  },

  created() {
    this.calculateDates();
    this.fetchData();
  },

  methods: {
    onEventPopupClosed() {
      //this.currentEvent = null;
    },

    openEventPopup(event) {
      this.currentEvent = event;
      this.eventPopupActive = true;
    },

    createEmptyEventData(start, employee) {
      let category = null;
      const catId = this.selectedCategories[0] || null;

      if(catId) {
        category = this.categories.find(item => item.id === catId);
      }

      return {
        title: null,
        description: null,
        content: null,
        url: null,
        start_at: moment(start).set("hour", 0).set("minute", 0).set("second", 0).format('YYYY-MM-DD HH:mm:ss'),
        end_at: null,
        is_whole_day: true,
        reminder_count: 0,
        reminder_interval: 0,
        reminder_unit: 'd',
        color: '1abc9c',
        user_id: employee.id,
        users: [{id: employee.id}],
        creator_user_id: this.userId,
        recurring_event: false,
        recurring_config: null,
        category: category
      }
    },

    onClickDate({date, employee}) {
      this.currentEvent = this.createEmptyEventData(date, employee);
      this.eventPopupActive = true;
    },
    onClickRefreshData() {
        console.log("test")
      this.fetchData();
    },

    fetchData() {
      this.$vs.loading()

      Promise.all([
        this.employeesPromise,
        this.categoriesPromise,
        this.holidaysPromise,
        this.eventsPromise,
        this.teamsPromise,
        this.specialEventsPromise,
        this.sprintEventsPromise,
        this.workspacesPromise
      ]).then(responses => {

        this.employees = responses[0].data.result.filter(employee => {
          return employee.detail.first_name !== 'SYSTEM'
        });

        this.categories = responses[1].data.result;

        this.holidays = responses[2].data.result.filter(holiday => {
          if (holiday.regions) {
            return holiday.regions.bw;
          }
          return false;
        });

        let events = [].concat(responses[3].data.result, responses[5].data.result);

        // To ensure reactivity of child components
        events.forEach(event => {
          event.backgroundColor = null;
          event.gridColumn = '';
          event.continuesBefore = false;
          event.continuesAfter = false;
          event.gridRow = '';
        });

        this.events = events;

        this.teams = responses[4].data.result;
        this.workspaces = responses[7].data.result;

        this.$vs.loading.close();
        this.loaded = true;
      });
    },

    onEventUpdate(event) {
      if (!event || !event.id) {
        return;
      }

      this.$vs.loading();

      ApiService.put('calendar-events/' + event.id, {
        start_at: moment(event.start_at).format('YYYY-MM-DD HH:mm:ss'),
        end_at: moment(event.end_at).isValid() ? moment(event.end_at).format('YYYY-MM-DD HH:mm:ss') : null,
        title: event.title,
        users: event.users
      }).then(response => {
        this.reloadEvents();
      }).catch(error => {
        console.log(error);
        this.$vs.loading.close();
      });

    },

    reloadEvents() {
      this.$vs.loading();

      const requests = [
        ApiService.get('calendar-events', {
          params: {
            includes: ['users.teams'],
          },
          paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
        }),
        this.specialEventsPromise,
      ];

      Promise.all(requests).then(responses => {
        const events = [].concat(responses[0].data.result, responses[1].data.result);
        // To ensure reactivity of child components
        events.forEach(event => {
          event.backgroundColor = null;
          event.gridColumn = '';
          event.continuesBefore = true;
          event.continuesAfter = true;
          event.gridRow = '';
        });

        this.events = events;

        this.$vs.loading.close();
      }).catch(error => {
        console.log(error);
        this.$vs.loading.close();
      });

    },

    onSubtractDateRange() {
      this.startDate = moment(this.startDate).subtract(7, 'days');
      this.calculateDates();
    },

    onSetDateRangeToToday() {
      this.startDate = moment();
      this.calculateDates();
    },

    onAddDateRange() {
      this.startDate = moment(this.startDate).add(7, 'days');
      this.calculateDates();
    },

    calculateDates() {
      let days = cloneDeep(this.dayCount);
      let date = moment(this.startDate).startOf('week');
      let dates = []

      while (days > 0) {
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          dates.push(moment(date));
          days -= 1;
        }

        date.add(1, 'days');
      }

      this.dates = dates;
    }

  }

}
</script>

<style lang="scss">
@import './style';
</style>