<template>
  <div class="scheduler-row">
    <template v-if="eventsInitiallyComputed">
      <div class="scheduler-col col-employee">
        <div class="avatar">
          <gravatar-img :email="employee.email" class="rounded-full shadow-md cursor-pointer block" width="36"
                        height="36"/>
        </div>
        <div class="name">{{ employee.detail.first_name }} {{ employee.detail.last_name }}<br>
            <small v-if="employee.workspace">{{ employee.workspace.unique_name }}</small>
        </div>
      </div>

      <div class="scheduler-events">
        <div class="click-handler-grid">
          <template v-for="date in dates">
            <div class="click-handler"
                 @click="onClickHandler(date)"
                 @drop="onDrop($event, date)"
                 @dragover.prevent
                 @dragenter="onDragEnter($event, date)"
                 @dragleave="onDragLeave($event, date)">
            </div>
          </template>
        </div>

        <div class="event-grid">
          <template v-for="event in events">
            <scheduler-event
                :event="event"
                :employee="employee"
                :dates="dates"
                @eventClick="onEventClicked"
                @eventUpdate="onUpdateEvent"
                @eventDragging="onDragging"
                @dragStart="onDragStart"
                @dragEnd="onDragEnd"
            >
            </scheduler-event>
          </template>
        </div>

      </div>

      <!--
      <template v-for="date in dates">
        <div class="scheduler-col"></div>
      </template>
      -->
    </template>
  </div>
</template>

<script>
import GravatarImg from "vue-gravatar/src/components/GravatarImg";
import moment from 'moment';
import cloneDeep from "lodash/cloneDeep";
import draggable from 'vuedraggable'
import SchedulerEvent from "./SchedulerEvent";
import findIndex from "lodash/findIndex";

const businessMoment = require('moment-business-days');

export default {
  name: "SchedulerRow",
  components: {
    SchedulerEvent,
    GravatarImg,
    draggable
  },
  props: {
    employee: {
      type: Object,
      required: true
    },
    dates: {
      type: Array,
      required: true
    },
    dateRangeEvents: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      currentDragEvent: null,
      currentDragEventDir: 'end',
      initialEvents: [],
      events: [],
      timeoutId: null,
      inlineEditId: null,
      eventsInitiallyComputed: false,
    }
  },
  computed: {
    startDate() {
      return moment(this.dates[0]).startOf('day');
    },
    endDate() {
      return moment(this.dates[this.dates.length - 1]).endOf('day');
    }
  },

  created() {
    let events = cloneDeep(this.dateRangeEvents);

    this.initialEvents = events.filter(event => {
      return event.users.some(u => u.id === this.employee.id)
    });

    this.events = cloneDeep(this.initialEvents);

    this.computeEvents();
  },

  methods: {
    onDrop(evt, date) {
      evt.target.classList.remove('highlight');

      const eventId = evt.dataTransfer.getData('eventId'),
          event = this.dateRangeEvents.find(item => item.id === parseInt(eventId)),
          clone = cloneDeep(event),
          dayDiff = moment(date).startOf('day').diff(moment(clone.start_at).startOf('day'), 'days');

      let endDiff = undefined,
          endAdd = 0,
          userChanged = false;

      if (!clone.users.some(user => user.id === this.employee.id)) {
        clone.users = [{id: this.employee.id}];
        userChanged = true;
      }

      if (dayDiff === 0) {
        if (userChanged) {
          this.$emit('eventUpdate', clone);
        }
        return;
      }

      if (clone.end_at) {
        endDiff = businessMoment(clone.end_at).startOf('day').businessDiff(businessMoment(clone.start_at).startOf('day'), 'days')
      }

      clone.start_at = moment(clone.start_at).add(dayDiff, 'days').format('YYYY-MM-DD HH:mm:ss');

      if (clone.end_at && endDiff) {
        let tmpDate = businessMoment(clone.start_at);

        while (endDiff > 0) {
          tmpDate = businessMoment(tmpDate).add(1, 'days');
          endAdd += 1;
          if (businessMoment(tmpDate).isBusinessDay()) {
            endDiff -= 1;
          }
        }
      }

      if (clone.end_at && endAdd && endAdd !== 0 && endAdd > 0) {
        clone.end_at = businessMoment(clone.start_at).add(endAdd, 'days').format('YYYY-MM-DD HH:mm:ss');
      } else {
        clone.end_at = null;
      }

      this.$emit('eventUpdate', clone);
    },

    onDragEnter(event) {
      event.target.classList.add('highlight')
    },

    onDragLeave(event) {
      event.target.classList.remove('highlight')
    },

    eventKey(event) {
      return '' + this.employee.id + '' + event.id;
    },

    onClickHandler(date) {
      this.$emit('clickDate', {date: date, employee: this.employee});
    },

    onEventClicked(event) {
      this.$emit('clickEvent', event);
    },

    onUpdateEvent(event) {
      this.$emit('eventUpdate', event);
    },

    computeEvents() {
      new Promise((resolve) => {
        let events = this.events;

        this.events = [];

        for (let i = 0; i < events.length; i++) {
          events[i].backgroundColor = events[i].color ? `#${events[i].color}` : null;
          events[i].gridColumn = this.getGridColumnStyling(events[i]);
          events[i].continuesBefore = this.getContinuesBefore(events[i]);
          events[i].continuesAfter = this.getContinuesAfter(events[i]);
        }

        events = this.addGridRowStyling(events);

        this.events = events;

        resolve(true);

      }).then(() => {
        this.eventsInitiallyComputed = true;
      });
    },

    computeEvent(event) {
      event.backgroundColor = event.color ? `#${event.color}` : null;
      event.gridColumn = this.getGridColumnStyling(event);
      event.continuesBefore = this.getContinuesBefore(event);
      event.continuesAfter = this.getContinuesAfter(event);
      return event;
    },

    getContinuesBefore(event) {
      return businessMoment(event.start_at).startOf('day')
          .businessDiff(businessMoment(this.startDate).startOf('day'), true) < 0;
    },
    getContinuesAfter(event) {
      if (!event.end_at) {
        return false;
      }

      return businessMoment(event.end_at).endOf('day')
          .businessDiff(businessMoment(this.endDate).endOf('day'), true) > 0;
    },
    getGridColumnStyling(event) {
      let column = "",
          start = businessMoment(event.start_at).startOf('day')
              .businessDiff(businessMoment(this.startDate).startOf('day'), true),
          end = null;

      if (start < 0) {
        start = 0;
      }

      if (event.end_at !== null && moment(event.end_at).isValid()) {
        end = businessMoment(event.end_at).endOf('day')
            .businessDiff(businessMoment(this.startDate).startOf('day'), true);
      }

      start = start + 1;

      if (start < 1) {
        column += 1
      } else {
        column += start;
      }

      if (end === null) {
        end = start;
      }

      if (end !== null) {

        end = end + 1;

        if (end <= this.dates.length) {
          let span = (end - start);
          span = span > 0 ? span : 1;

          column += " / span " + span;
        } else {
          let span = (this.dates.length - start + 1);
          span = span > 0 ? span : 1;

          column += " / span " + span;
        }

      } else {
        column += " / span 1";
      }

      return column;
    },

    addGridRowStyling(events) {
      if (!events.length) {
        return [];
      }

      const itemRows = [];

      for (let d = 0; d < this.dates.length; d++) {
        itemRows.push([]);
      }

      for (let i = 0; i < events.length; i++) {
        const event = events[i];

        const start = businessMoment(event.start_at).startOf('day')
            .businessDiff(businessMoment(this.startDate).startOf('day'), true);

        const startOffset = start < 0 ? 0 : start;

        let span = 0;

        if (event.end_at !== null) {
          span = businessMoment(event.end_at).endOf('day')
              .businessDiff(businessMoment(this.startDate).startOf('day'), true) - 1;
        }

        event.itemRow = 0;

        for (let d = 0; d < this.dates.length; d++) {
          if (d === startOffset) {
            let s = 0
            while (itemRows[d][s]) {
              s++
            }

            event.itemRow = s
            itemRows[d][s] = true
          } else if (d <= span) {
            itemRows[d][event.itemRow] = true
          }
        }
        event.gridRow = event.itemRow + 1;
      }

      return events;
    },


    onDragStart() {
      this.$emit('dragStart');
    },

    onDragging(event) {
      this.computeEvent(event);
      this.events.push();
      this.$emit('dragging');
    },

    onDragEnd() {
      this.$emit('dragEnd');
    },
  },

  watch: {
    dateRangeEvents() {
      this.inlineEditId = null;

      let events = cloneDeep(this.dateRangeEvents);

      this.initialEvents = events.filter(event => {
        return event.users.some(u => u.id === this.employee.id)
      });

      this.events = cloneDeep(this.initialEvents);

      this.computeEvents();
    }
  },
}
</script>

<style scoped>

</style>