<template>
  <div class="scheduler-borders">
    <div class="scheduler-borders-col"></div>

    <template v-for="(date, index) in dates">
      <div class="scheduler-borders-col"
           :class="colClasses(date)"
           :data-index="index">
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "SchedulerBorders",
  props: {
    dates: {
      type: Array,
      required: true
    },
    holidays: {
      type: Array,
      required: true
    }
  },
  methods: {
    isEndOfWeek(date) {
      return moment(date).isoWeekday() === 5;
    },
    isStartOfWeek(date) {
      return moment(date).isoWeekday() === 1;
    },
    getHoliday(date) {
      return this.holidays.find(holiday => {
        return holiday.date === moment(date).format('YYYY-MM-DD');
      });
    },
    colClasses(date) {
      return [{
        'is--end-of-week': this.isEndOfWeek(date),
        'is--start-of-week': this.isStartOfWeek(date),
        'is--holiday': !!this.getHoliday(date),
        'is--today': moment(date).isSame(moment(), 'day')
      }];
    }
  }
}
</script>

<style scoped>

</style>