var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"scheduler-event",class:{
              'border-left-none': _vm.event.continuesBefore,
               'border-right-none': _vm.event.continuesAfter,
               'is--dragging': _vm.isDragging,
               'scheduler-event-no-drag': !!_vm.event.special_event
             },staticStyle:{"grid-row":"1"},style:({
               gridColumn: _vm.event.gridColumn,
               gridRow: _vm.event.gridRow,
               background: _vm.event.backgroundColor
             })},[(!!_vm.event.special_event)?_c('div',{staticClass:"handle-bar-do-drag"}):(!_vm.event.continuesBefore)?_c('div',{staticClass:"handle-bar",on:{"mousedown":function($event){return _vm.dragStart('start')}}}):_c('div',{staticClass:"continue-shadow-before"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inlineEdit),expression:"!inlineEdit"}],staticClass:"title",attrs:{"draggable":"true"},on:{"click":_vm.onClickTitle,"dragstart":_vm.startEventDrag,"drag":_vm.onEventDragging,"dragend":_vm.onEventDragEnd}},[_vm._v(" "+_vm._s(_vm.event.title)+" ")]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(_vm.inlineEdit),expression:"inlineEdit"}],staticClass:"inline-edit-form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitTitle.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.event.tmpTitle),expression:"event.tmpTitle"}],ref:"titleInput",attrs:{"type":"text"},domProps:{"value":(_vm.event.tmpTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.event, "tmpTitle", $event.target.value)}}})]),(!!_vm.event.special_event)?_c('div',{staticClass:"handle-bar-do-drag"}):(!_vm.event.continuesAfter)?_c('div',{staticClass:"handle-bar",on:{"mousedown":function($event){return _vm.dragStart('end')}}}):_c('div',{staticClass:"continue-shadow-after"})])}
var staticRenderFns = []

export { render, staticRenderFns }