<template>
  <div class="scheduler-filters bg-white rounded relative shadow p-2 mb-6">

      <div class="col-options--button refresh-icon" title="Refresh data" >
          <vs-button color="dark" class="mr-2" @click="onClickReloadEvents">Reload</vs-button>
      </div>

      <div class="scheduler-filter filter-teams w-1/6 pr-1">
          <v-select
                  @input="onFilterWorkspaces"
                  class="shadow"
                  v-model="selectedWorkspaces"
                  :options="workspaces"
                  label="unique_name"
                  placeholder="Workspace filtern"
                  autowidth
                  multiple
                  clearable>
          </v-select>
      </div>

    <div class="scheduler-filter filter-teams w-1/6 pr-1">
      <v-select
          @input="onFilterTeams"
          class="shadow"
          v-model="selectedTeams"
          :options="teams"
          label="name"
          placeholder="Teams filtern"
          autowidth
          multiple
          clearable>
      </v-select>
    </div>

    <div class="scheduler-filter filter-users w-1/4 pl-1">
      <v-select
          @input="onFilterUsers"
          class="shadow"
          v-model="selectedUsers"
          :options="filteredUsers"
          placeholder="Benutzer filtern"
          autowidth
          multiple
          clearable
          label="name">
      </v-select>
    </div>

    <div class="scheduler-filter filter-users w-1/6 pl-1">
      <v-select
          @input="onFilterCategories"
          class="shadow"
          v-model="selectedCategories"
          :options="categories"
          placeholder="Kategorien filtern"
          autowidth
          multiple
          clearable
          label="name">
      </v-select>
    </div>

    <div class="scheduler-filter filter-date-range">
      <vs-button icon="navigate_before" @click="onClickSubtractDateRange"></vs-button>
      <span class="date-range">
            {{ dateStart }} - {{ dayEnd }}
      </span>
      <vs-button icon="navigate_next" @click="onClickAddDateRange"></vs-button>
      <vs-button color="dark" class="ml-2" @click="onClickToday">Heute</vs-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from 'vue-select'

export default {
  name: "SchedulerFilter",
  components: {
    'v-select': vSelect,
  },
  props: {
    dates: {
      type: Array,
      required: true
    },
    teams: {
      type: Array,
      required: true
    },
    workspaces: {
      type: Array,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedTeams: [],
      selectedWorkspaces: [],
      selectedUsers: [],
      selectedCategories: [],
      filteredUsers: [],
    }
  },
  created() {
    this.filteredUsers = this.users.map((user) => {
      return {id: user.id, name: `${user.detail.first_name} ${user.detail.last_name} (${user.workspace.unique_name.charAt(0)})`, workspace: `${user.workspace.unique_name}`}
    }, []);
  },
  computed: {
    dateStart() {
      return moment(this.dates[0]).format('DD.MM.YYYY');
    },

    dayEnd() {
      return moment(this.dates[this.dates.length - 1]).format('DD.MM.YYYY');
    }
  },
  methods: {
      onClickReloadEvents() {
      this.$emit('reload')
    },
    onClickSubtractDateRange() {
      this.$emit('subtract')
    },
    onClickAddDateRange() {
      this.$emit('add')
    },
    onClickToday() {
      this.$emit('today')
    },
    onFilterUsers() {
      this.$emit('filterUsers', this.selectedUsers.map(user => user.id, []));
    },
    onFilterTeams() {
      this.$emit('filterTeams', this.selectedTeams.map(team => team.id, []));
    },
    onFilterWorkspaces() {
      this.$emit('filterWorkspaces', this.selectedWorkspaces.map(workspace => workspace.id, []));
    },
    onFilterCategories() {
      this.$emit('filterCategories', this.selectedCategories.map(category => category.id, []));
    }
  },

}
</script>

<style scoped>

</style>