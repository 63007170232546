<template>
  <div class="scheduler-event"
       v-click-outside="onClickOutside"
       style="grid-row: 1;"
       :class="{
                'border-left-none': event.continuesBefore,
                 'border-right-none': event.continuesAfter,
                 'is--dragging': isDragging,
                 'scheduler-event-no-drag': !!event.special_event
               }"
       :style="{
                 gridColumn: event.gridColumn,
                 gridRow: event.gridRow,
                 background: event.backgroundColor
               }">

    <div class="handle-bar-do-drag" v-if="!!event.special_event"></div>
    <div class="handle-bar" v-else-if="!event.continuesBefore" @mousedown="dragStart('start')"></div>
    <div class="continue-shadow-before" v-else></div>

    <div
        class="title"
        @click="onClickTitle"
        v-show="!inlineEdit"
        draggable="true"
        @dragstart="startEventDrag"
        @drag="onEventDragging"
        @dragend="onEventDragEnd"
    >
      {{ event.title }}
    </div>

    <form action="#" class="inline-edit-form" v-show="inlineEdit"
          @submit.prevent="onSubmitTitle">
      <input type="text" v-model="event.tmpTitle" ref="titleInput">
    </form>

    <div class="handle-bar-do-drag" v-if="!!event.special_event"></div>
    <div class="handle-bar" v-else-if="!event.continuesAfter" @mousedown="dragStart('end')"></div>
    <div class="continue-shadow-after" v-else></div>

  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SchedulerEvent",

  props: {
    event: {
      type: Object
    },
    employee: {
      type: Object
    },
    dates: {
      type: Array
    },
  },

  data() {
    return {
      currentDragDirection: null,
      isDragging: false,
      inlineEdit: null,
      timeoutId: null,
      elClone: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.bindEvents()
    })
  },

  beforeDestroy() {
    this.unbindEvents()
  },

  methods: {
    startEventDrag(evt) {
      this.createElGhost();

      const dragImgEl = document.createElement('span');
      evt.dataTransfer.setDragImage(dragImgEl, 0, 0);

      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('eventId', this.event.id);

      setTimeout(() => {
        this.addHtmlClass('is--event-dragging')
      }, 50);

    },

    createElGhost() {
      const width = this.$el.offsetWidth,
          height = this.$el.offsetHeight;

      const clone = this.$el.cloneNode(true);

      clone.style.gridColumn = null;
      clone.style.gridRow = null;

      clone.style.width = width + 'px';
      clone.style.height = height + 'px';
      clone.style.position = 'absolute';
      clone.style.zIndex = '99999';
      clone.style.pointerEvents = 'none';

      document.body.appendChild(clone);

      this.elClone = clone;
    },

    onEventDragging(evt) {
      const dragX = evt.pageX,
          dragY = evt.pageY,
          height = this.elClone.offsetHeight / 2;

      if (dragX === 0 && dragY === 0) {
        return;
      }

      this.elClone.style.top = dragY - height + 'px';
      this.elClone.style.left = dragX - 10 + 'px';
    },

    onEventDragEnd() {
      this.elClone.remove();
      this.removeHtmlClass('is--event-dragging');
    },

    eventKey() {
      return '' + this.employee.id + '' + this.event.id;
    },

    onClickTitle() {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.$emit('eventClick', this.event);
          this.timeoutId = null;
        }, 200);
      } else {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;

        this.event.tmpTitle = this.event.title;
        this.inlineEdit = true;

        const input = this.$refs['titleInput'];
        this.$nextTick(() => input.focus())
      }
    },

    onClickOutside() {
      this.inlineEdit = false;
    },

    onSubmitTitle() {
      this.inlineEdit = false;
      if (this.event.title !== this.event.tmpTitle) {
        this.event.title = this.event.tmpTitle;
        this.$emit('eventUpdate', this.event);
      }
    },

    bindEvents() {
      document.addEventListener('mousemove', this.dragging)
      document.addEventListener('mouseup', this.dragEnd)
      document.addEventListener('mouseleave', this.dragEnd)
    },
    unbindEvents() {
      document.removeEventListener('mousemove', this.dragging)
      document.removeEventListener('mouseup', this.dragEnd)
      document.removeEventListener('mouseleave', this.dragEnd)
    },

    dragStart(dir = 'end') {
      this.currentDragDirection = dir;
      this.event.changed = false;
      this.event.initialEndAt = this.event.end_at;
      this.event.initialStartAt = this.event.start_at;

      this.isDragging = true
      this.addHtmlClass('is--event-span-dragging');

      this.$emit('dragStart');
    },

    dragging(e) {
      if (!this.isDragging) {
        return false
      }

      this.updateEventDate(e, this.currentDragDirection);
      this.$emit('dragging');
      e.preventDefault();
    },

    dragEnd(e) {
      if (!this.isDragging) {
        return false;
      }

      this.$emit('dragEnd');

      if (this.event.changed) {
        this.$emit('eventUpdate', this.event);
      }

      this.isDragging = false
      this.removeHtmlClass('is--event-span-dragging');
    },

    addHtmlClass(cls) {
      document.querySelector('html').classList.add(cls);
    },

    removeHtmlClass(cls) {
      document.querySelector('html').classList.remove(cls);
    },

    updateEventDate(e, dir = 'end') {
      const x = e.clientX, y = e.clientY,
          elem = document.elementFromPoint(x, y),
          event = this.event;

      let oldStart = moment(event.initialStartAt).format('YYYY-MM-DD'),
          oldEnd = moment(event.initialEndAt);

      if (!oldEnd.isValid()) {
        oldEnd = oldStart;
      } else {
        oldEnd = oldEnd.format('YYYY-MM-DD');
      }

      if (!elem || !elem.classList.contains('scheduler-borders-col')) {
        return;
      }

      const dateIndex = parseInt(elem.dataset.index),
          targetDay = this.dates[dateIndex].startOf('day');

      if (!targetDay) {
        return;
      }

      let currentDate = moment(event.start_at);

      if (dir === 'end') {
        currentDate = moment(event.end_at);

        if (!currentDate.isValid()) {
          currentDate = moment(event.start_at);
        }
      }


      let dayDiff = targetDay.diff(moment(currentDate).startOf('day'), 'days');

      if (dayDiff === 0) {
        return;
      }

      const newDate = currentDate.add(dayDiff, 'days');

      if (dir === 'end') {
        let checkDiff = newDate.diff(moment(event.start_at).startOf('day'), 'days') >= 0;

        if (!checkDiff) {
          return;
        }
      } else {
        let endDate = moment(event.start_at);

        if (event.end_at && moment(event.end_at).isValid()) {
          endDate = moment(event.end_at);
        }

        let checkDiff = endDate.diff(newDate.startOf('day'), 'days') >= 0;

        if (!checkDiff) {
          return;
        }

      }

      if (dir === 'end') {
        event.end_at = newDate;
      } else {
        if (!event.end_at || !moment(event.end_at).isValid()) {
          event.end_at = moment(event.start_at);
        }

        event.start_at = newDate;
      }

      event.changed = !(moment(event.start_at).format('YYYY-MM-DD') === oldStart && moment(event.end_at).format('YYYY-MM-DD') === oldEnd);
      this.$emit('eventDragging', event);
    },

  },

  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    }
  }
}
</script>

<style scoped>

</style>