<template>
  <div class="scheduler-headline">
    <div class="scheduler-headline-col"></div>

    <template v-for="date in dates">
      <div class="scheduler-headline-col"
           :class="colClasses(date)">
        <div class="date">{{ getDayDate(date) }}</div>
        <div class="month">{{ getMonthDate(date) }}</div>
        <span class="name">{{ getDayName(date) }}</span>

        <span class="details">
          <template v-if="getHoliday(date)">
                {{ getHoliday(date).name }}
          </template>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: "SchedulerHeadline",
  props: {
    dates: {
      type: Array,
      required: true
    },
    holidays: {
      type: Array,
      required: true
    }
  },
  methods: {
    getDayName(date) {
      return moment(date).locale('de').format('dddd');
    },
    getDayDate(date) {
      return moment(date).format('DD');
    },
    getMonthDate(date) {
      return moment(date).format('MMMM');
    },
    isEndOfWeek(date) {
      return moment(date).isoWeekday() === 5;
    },
    isStartOfWeek(date) {
      return moment(date).isoWeekday() === 1;
    },
    getHoliday(date) {
      return this.holidays.find(holiday => {
        return holiday.date === moment(date).format('YYYY-MM-DD');
      });
    },
    colClasses(date) {
      return {
        'is--end-of-week': this.isEndOfWeek(date),
        'is--start-of-week': this.isStartOfWeek(date),
        'is--holiday': !!this.getHoliday(date),
        'is--today': moment(date).isSame(moment(), 'day')
      }
    }
  }
}
</script>

<style scoped>

</style>